<script setup>
import { useForm } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    events: Array,
    requester: String,
});

const form = useForm({
    event_id: props.events[0]?.id || null,
    number_people_present: null,
    number_visitors: 0,
    number_people_saved: 0,
    number_people_cured: 0,
    number_people_baptized_holy_spirit: 0,
    number_people_youtube: 0,
    number_gv_teens: 0,
    number_gv_teens_saved: 0,
    number_children_zero_to_two_years_old: 0,
    number_children_three_to_six_years_old: 0,
    number_children_seven_to_twelve_years_old: 0,
    number_children_saved: 0,
    number_visitors_children: 0,
    number_teachers: 0,
    number_assistant_teachers: 0,
    number_leaders: 0,
    number_secretariats: 0,
    requester: props.requester,
});

const store = () => form.post('/dashboard/census');
</script>

<template>
    <Dashboard page-title="Novo Censo">
        <Breadcrumb
            current-page-name="Novo Censo"
            :link-list="[{ previous_page_url: 'census', previous_page_name: 'Censos' }]"
        />
        <h4 class="my-3">Novo Censo</h4>

        <div class="px-5 py-4 form-card">
            <form>
                <div class="row g-3 my-2 px-0">
                    <legend class="m-0">Dados iniciais</legend>

                    <div class="col-md-8">
                        <label for="event_id" class="form-label"> Evento </label>

                        <select id="event_id" v-model="form.event_id" class="form-select" required>
                            <option v-for="event in events" :key="event.id" :value="event.id">
                                {{ event.name }} - {{ event.start_time }}
                            </option>
                        </select>

                        <small v-if="form.errors.event_id" class="text-danger mt-3">
                            {{ form.errors.event_id }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_people_present" class="form-label">
                            Pessoas presentes
                        </label>

                        <input
                            id="number_people_present"
                            v-model.number="form.number_people_present"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_people_present" class="text-danger mt-3">
                            {{ form.errors.number_people_present }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_visitors" class="form-label"> Visitantes </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_visitors"
                            v-model.number="form.number_visitors"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_visitors" class="text-danger mt-3">
                            {{ form.errors.number_visitors }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_people_saved" class="form-label"> Pessoas salvas </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_saved"
                            v-model.number="form.number_people_saved"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_people_saved" class="text-danger mt-3">
                            {{ form.errors.number_people_saved }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_people_cured" class="form-label">
                            Pessoas curadas
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_cured"
                            v-model.number="form.number_people_cured"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_people_cured" class="text-danger mt-3">
                            {{ form.errors.number_people_cured }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_people_baptized_holy_spirit" class="form-label">
                            Pessoas batizadas no Espírito Santo
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_baptized_holy_spirit"
                            v-model.number="form.number_people_baptized_holy_spirit"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small
                            v-if="form.errors.number_people_baptized_holy_spirit"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_people_baptized_holy_spirit }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_people_youtube" class="form-label">
                            Pessoas no YouTube
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_youtube"
                            v-model.number="form.number_people_youtube"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_people_youtube" class="text-danger mt-3">
                            {{ form.errors.number_people_youtube }}
                        </small>
                    </div>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 mt-3 form-card mt-3">
            <form>
                <div class="row g-3 px-0 my-2">
                    <legend class="m-0">Crianças e Adolescentes</legend>

                    <div class="col-md-6">
                        <label for="number_visitors_children" class="form-label"
                            >Crianças Visitantes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_visitors_children"
                            v-model.number="form.number_visitors_children"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_visitors_children" class="text-danger mt-3">
                            {{ form.errors.number_visitors_children }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_gv_teens" class="form-label"> Adolescentes GV </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_gv_teens"
                            v-model.number="form.number_gv_teens"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_gv_teens" class="text-danger mt-3">
                            {{ form.errors.number_gv_teens }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_zero_to_two_years_old" class="form-label">
                            Crianças de 0 a 2 anos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_zero_to_two_years_old"
                            v-model.number="form.number_children_zero_to_two_years_old"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_children_zero_to_two_years_old"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_children_zero_to_two_years_old }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_gv_teens_saved" class="form-label">
                            Adolescentes GV salvos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_gv_teens_saved"
                            v-model.number="form.number_gv_teens_saved"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_gv_teens_saved" class="text-danger mt-3">
                            {{ form.errors.number_gv_teens_saved }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_three_to_six_years_old" class="form-label">
                            Crianças de 3 a 6 anos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_three_to_six_years_old"
                            v-model.number="form.number_children_three_to_six_years_old"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_children_three_to_six_years_old"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_children_three_to_six_years_old }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_saved" class="form-label"
                            >Crianças salvas
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_saved"
                            v-model.number="form.number_children_saved"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_children_saved" class="text-danger mt-3">
                            {{ form.errors.number_children_saved }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_seven_to_twelve_years_old" class="form-label">
                            Crianças de 7 a 12 anos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_seven_to_twelve_years_old"
                            v-model.number="form.number_children_seven_to_twelve_years_old"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_children_seven_to_twelve_years_old"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_children_seven_to_twelve_years_old }}
                        </small>
                    </div>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 form-card mt-3">
            <form @submit.prevent="method">
                <div class="row g-3 my-2 px-0">
                    <legend class="m-0">Outros</legend>

                    <div class="col-md-6">
                        <label for="number_teachers" class="form-label">
                            Professores presentes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_teachers"
                            v-model.number="form.number_teachers"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_teachers" class="text-danger mt-3">
                            {{ form.errors.number_teachers }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_assistant_teachers" class="form-label">
                            Assistentes de professores presentes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_assistant_teachers"
                            v-model.number="form.number_assistant_teachers"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_assistant_teachers"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_assistant_teachers }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_leaders" class="form-label"> Líderes presentes </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_leaders"
                            v-model.number="form.number_leaders"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_leaders" class="text-danger mt-3">
                            {{ form.errors.number_leaders }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_secretariats" class="form-label">
                            Secretários presentes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_secretariats"
                            v-model.number="form.number_secretariats"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_secretariats" class="text-danger mt-3">
                            {{ form.errors.number_secretariats }}
                        </small>
                    </div>
                </div>
            </form>

            <div class="col text-end mt-4">
                <button
                    class="btn btn-primary"
                    type="button"
                    :disabled="form.processing"
                    @click="store()"
                >
                    <span
                        v-if="form.processing"
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    />
                    Cadastrar
                </button>
            </div>
        </div>
    </Dashboard>
</template>
