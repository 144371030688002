<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    families: Object,
});

const goToFamilyPage = (familyId) => router.get(`/dashboard/families/${familyId}`);

const clearQueryParams = () => router.replace('/dashboard/families');

const searchForm = reactive({
    name: null,
    order_by: 'asc',
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () => router.get('/dashboard/families', searchForm, { preserveState: true });

const order = () => {
    searchForm.order_by = searchForm.order_by === 'asc' ? 'desc' : 'asc';
    seacher();
};

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Famílias">
        <Breadcrumb current-page-name="Famílias" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Famílias</h4>
                <Link :href="`/dashboard/families/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="row p-2">
            <div class="p-3 search-form-card">
                <div class="row align-items-center p-3">
                    <div class="col-lg-6 col-7 px-0">
                        <div class="input-group">
                            <span id="input-group-name" class="input-group-text bg-transparent">
                                <font-awesome-icon
                                    class="text-secondary"
                                    :icon="['fas', 'magnifying-glass']"
                                />
                            </span>
                            <input
                                type="text"
                                class="form-control"
                                placeholder="Buscar pelo nome"
                                aria-label="Buscar pelo nome"
                                aria-describedby="input-group-name"
                                @input="compositionSeacher('name', $event)"
                            />
                        </div>
                    </div>
                    <div class="col-lg-6 col-5 px-0">
                        <div class="d-flex justify-content-end">
                            <button type="button" class="btn btn-light border" @click="order()">
                                <font-awesome-icon
                                    v-if="searchForm.order_by == 'asc'"
                                    class="me-2"
                                    :icon="['fas', 'arrow-down-a-z']"
                                />
                                <font-awesome-icon
                                    v-else
                                    class="me-2"
                                    :icon="['fas', 'arrow-down-z-a']"
                                />
                                Ordernar
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row p-3">
                    <div class="table-responsive">
                        <table class="table m-0 align-middle table-hover" id="table-families">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">#</th>
                                    <th scope="col">Nome da família</th>
                                    <th class="text-center" scope="col">Membros</th>
                                    <th class="text-center" scope="col">MOI</th>
                                    <th class="text-center" scope="col">MFC</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(family, index) in families.data"
                                    :key="index"
                                    @click="goToFamilyPage(family.id)"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td>
                                        {{ family.name }}
                                    </td>
                                    <td class="text-center">
                                        <template v-if="family.members.length">
                                            <Link
                                                v-for="(event, index) in family.members"
                                                :key="index"
                                                :href="`/dashboard/members/${event.id}`"
                                            >
                                                <img
                                                    height="30"
                                                    width="30"
                                                    class="rounded-circle border border-1 border-ligth"
                                                    :src="`${event.identification_photo}`"
                                                    alt=""
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="bottom"
                                                    :data-bs-title="event.name"
                                                    style="margin-left: -8px"
                                                />
                                            </Link>
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{ family.church_family_members }}
                                        <font-awesome-icon
                                            v-if="
                                                family.church_family_members ==
                                                family.members.length
                                            "
                                            class="ms-2 text-success"
                                            :icon="['fas', 'circle-check']"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            class="ms-2 text-ligth"
                                            :icon="['fas', 'circle-check']"
                                        />
                                    </td>
                                    <td class="text-center">
                                        {{ family.believing_family_members }}
                                        <font-awesome-icon
                                            v-if="
                                                family.believing_family_members ==
                                                family.members.length
                                            "
                                            class="ms-2 text-success"
                                            :icon="['fas', 'circle-check']"
                                        />
                                        <font-awesome-icon
                                            v-else
                                            class="ms-2 text-ligth"
                                            :icon="['fas', 'circle-check']"
                                        />
                                    </td>
                                </tr>
                            </tbody>

                            <tfoot class="bg-white">
                                <tr>
                                    <td colspan="5">
                                        <Pagination
                                            :data="families.meta"
                                            reference-date="families"
                                            identification="Famílias"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
