<script setup>
import { ref, nextTick } from 'vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

const props = defineProps({
    title: String,
    id: String,
    urlImage: String,
});

const emit = defineEmits(['image-resized']);
const imageURL = ref('');
const cropperRef = ref(null);
const inputRef = ref(null);
const dragOver = ref(false);

const handleFileChange = (event) => {
    const files = event.target.files || event.dataTransfer.files;
    if (files && files[0]) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            imageURL.value = e.target.result;
            nextTick(() => {
                if (cropperRef.value) {
                    cropperRef.value.replace(imageURL.value);
                }
            });
        };
        reader.readAsDataURL(file);
    }
};

const triggerFileInput = () => {
    inputRef.value.click();
};

const handleDragOver = (event) => {
    event.preventDefault();
    dragOver.value = true;
};

const handleDragLeave = () => {
    dragOver.value = false;
};

const handleDrop = (event) => {
    event.preventDefault();
    dragOver.value = false;
    handleFileChange(event);
};

const moveLeft = () => {
    if (cropperRef.value) cropperRef.value.move(-10, 0);
};

const moveRight = () => {
    if (cropperRef.value) cropperRef.value.move(10, 0);
};

const moveUp = () => {
    if (cropperRef.value) cropperRef.value.move(0, -10);
};

const moveDown = () => {
    if (cropperRef.value) cropperRef.value.move(0, 10);
};

const zoomIn = () => {
    if (cropperRef.value && cropperRef.value.cropper) cropperRef.value.cropper.zoom(0.1);
};

const zoomOut = () => {
    if (cropperRef.value && cropperRef.value.cropper) cropperRef.value.cropper.zoom(-0.1);
};

const resetCropper = () => {
    if (cropperRef.value && cropperRef.value.cropper) cropperRef.value.cropper.reset();
};

const saveChanges = () => {
    if (cropperRef.value) {
        cropperRef.value.getCroppedCanvas().toBlob((blob) => {
            emit('image-resized', blob);
        });
    }
};
</script>

<template>
    <div
        class="modal fade photo-edit-modal"
        :id="id"
        tabindex="-1"
        :aria-labelledby="`${id}ModalLabel`"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" :id="`${id}ModalLabel`">
                        {{ title }}
                    </h1>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <input
                        type="file"
                        ref="inputRef"
                        class="file-input d-none"
                        @change="handleFileChange"
                    />

                    <div
                        class="cropper-wrapper"
                        v-if="imageURL"
                        @dragover="handleDragOver"
                        @dragleave="handleDragLeave"
                        @drop="handleDrop"
                        :class="{ 'drag-over': dragOver }"
                    >
                        <VueCropper
                            ref="cropperRef"
                            :src="imageURL"
                            guides
                            containerStyle="width: 100%; height: 300px;"
                        />
                    </div>
                    <div
                        class="image-selection-field"
                        v-else
                        @dragover="handleDragOver"
                        @dragleave="handleDragLeave"
                        @drop="handleDrop"
                        @click.prevent="triggerFileInput"
                        :class="{ 'drag-over': dragOver }"
                    >
                        <div class="p-3 text-center">
                            <font-awesome-icon
                                class="cloud-icon"
                                :icon="['fas', 'cloud-arrow-up']"
                            />
                            <p class="mt-4">Mova o seu arquivo de foto para esta área</p>
                        </div>
                    </div>

                    <div class="d-flex justify-content-center mt-4" v-if="imageURL">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="moveLeft"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Mover esquerda"
                            >
                                <font-awesome-icon :icon="['fas', 'left-long']" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="moveRight"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Mover direita"
                            >
                                <font-awesome-icon :icon="['fas', 'right-long']" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="moveUp"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Mover acima"
                            >
                                <font-awesome-icon :icon="['fas', 'up-long']" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="moveDown"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Mover abaixo"
                            >
                                <font-awesome-icon :icon="['fas', 'down-long']" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="zoomIn"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Ampliar"
                            >
                                <font-awesome-icon :icon="['fas', 'magnifying-glass-plus']" />
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="zoomOut"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Reduzir"
                            >
                                <font-awesome-icon :icon="['fas', 'magnifying-glass-minus']" />
                            </button>
                        </div>
                        <div class="btn-group ms-3" role="group" aria-label="Basic example">
                            <button
                                @click="resetCropper"
                                type="button"
                                class="btn btn-primary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Reinicar"
                            >
                                <font-awesome-icon :icon="['fas', 'arrows-rotate']" />
                            </button>
                            <button
                                @click="triggerFileInput"
                                type="button"
                                class="btn btn-primary"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                data-bs-title="Adicionar foto"
                            >
                                <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Fechar
                    </button>
                    <button
                        type="button"
                        class="btn btn-success"
                        @click="saveChanges"
                        data-bs-dismiss="modal"
                    >
                        Salvar Alterações
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
