<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

// Components

import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import ScheduleLayout from '../Layout.vue';
import EventCard from '@/Pages/Dashboard/Pages/Events/Components/EventCard.vue';

const props = defineProps({
    events: Object,
    schedule: Object,
    ministers: Object,
    leaders: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    name: null,
    minister_id: 0,
    leader_id: 0,
    start_date: '',
    end_date: '',
});

const seacher = () =>
    router.get(`/dashboard/schedules/${props.schedule.id}/events`, searchForm, {
        preserveState: true,
    });

onMounted(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('name')) {
        searchForm.name = urlParams.get('name');
    }
    if (urlParams.has('start_date')) {
        searchForm.start_date = urlParams.get('start_date');
    }
    if (urlParams.has('end_date')) {
        searchForm.end_date = urlParams.get('end_date');
    }
    if (urlParams.has('minister_id')) {
        searchForm.minister_id = urlParams.get('minister_id');
    }
    if (urlParams.has('leader_id')) {
        searchForm.leader_id = urlParams.get('leader_id');
    }
});
</script>

<template>
    <ScheduleLayout :schedule="schedule">
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Eventos</h4>
                <Link
                    :href="`/dashboard/events/create/${schedule.id}`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label" for="floatingInput">Nome do evento</label>
                        <input
                            id="floatingInput"
                            v-model="searchForm.name"
                            type="email"
                            class="form-control"
                            placeholder="Culto de celebração"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                    <div class="col-6 col-sm-3">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6 col-sm-3">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar ministro</label
                        >
                        <select
                            id="minister_id"
                            v-model="searchForm.minister_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os ministros</option>
                            <option
                                v-for="(item, index) in ministers"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar dirigente</label
                        >
                        <select
                            id="leader_id"
                            v-model="searchForm.leader_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os dirigentes</option>
                            <option v-for="(item, index) in leaders" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <hr />
                <EventCard v-for="(item, index) in events.data" :key="index" :event="item" />
                <Pagination :data="events.meta" reference-date="events" identification="Eventos" />
            </div>
        </div>
    </ScheduleLayout>
</template>
