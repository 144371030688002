<template>
    <div>
        <div id="chart-pie">
            <VueApexCharts
                type="pie"
                height="250"
                ref="chart"
                :options="chartOptions"
                :series="series"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { ref, reactive, defineProps, onMounted } from 'vue';

const props = defineProps({
    series: {
        required: true,
        type: Array,
    },
    labels: {
        required: true,
        type: Array,
    },
});

const chart = ref(null);

const chartOptions = reactive({
    chart: {
        type: 'pie',
        height: 400,
    },
    labels: props.labels,
    legend: {
        position: 'bottom',
        horizontalAlign: 'center',
    },
});
</script>
