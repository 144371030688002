<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const form = useForm({
    name: null,
    description: null,
    date_completion: null,
    status: 1,
    identification_photo: '/img/avatars/male.png',
});

const store = () => form.post('/dashboard/evangelizations');

const inputRef = ref(null);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.identification_photo = e.target.result;
    };
    reader.readAsDataURL(blob);
};
</script>

<template>
    <Dashboard page-title="Nova evangelização">
        <Breadcrumb
            current-page-name="Nova evangelização"
            :link-list="[
                {
                    previous_page_url: 'evangelizations',
                    previous_page_name: 'Evangelizações',
                },
            ]"
        />
        <h4 class="my-3">Nova evangelização</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="d-flex justify-content-center">
                <div class="position-relative">
                    <img
                        :src="form.identification_photo"
                        alt="Identification Photo"
                        class="upload-box img-fluid shadow-sm"
                    />
                    <div class="position-absolute bottom-0 end-0 m-3">
                        <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#photoUpdateModal"
                            class="btn btn-edit shadow"
                        >
                            <font-awesome-icon class="text-white" :icon="['fas', 'pen']" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <label for="name" class="form-label"> Nome da evangelização </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Aviva ilha"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="status" class="form-label">Situação</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option selected value="1">Ativa</option>
                    <option value="0">Encerrada</option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="date_completion" class="form-label">Data de início</label>
                <input
                    id="date"
                    v-model="form.date_completion"
                    type="date"
                    autocomplete="off"
                    class="form-control"
                    required
                />
            </div>

            <div class="col-md-12">
                <label for="description" class="form-label"> Descrição </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                    name="description"
                    placeholder="Forneça um breve resumo dessa evangelização"
                    rows="5"
                />

                <small v-if="form.errors.description" class="text-danger mt-3">
                    {{ form.errors.description }}
                </small>
            </div>
            <input type="file" ref="inputRef" class="file-input d-none" />
        </Form>
        <PhotoEditModal
            v-if="form.identification_photo"
            title="Adicionar foto"
            id="photoUpdateModal"
            @image-resized="handleImageResized"
        />
    </Dashboard>
</template>
