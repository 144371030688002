<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    evangelizations: Object,
});

const goToEvangelizationPage = (evangelizationId) =>
    router.get(`/dashboard/evangelizations/${evangelizationId}`);

const clearQueryParams = () => router.replace('/dashboard/evangelizations');

const searchForm = reactive({
    name: null,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/evangelizations', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Evangelizações">
        <Breadcrumb current-page-name="Evangelizações" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Evangelizações</h4>
                <Link :href="`/dashboard/evangelizations/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome do evento</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Aviva Ilha"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>
            <div class="row px-4 mt-3">
                <hr />
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-evangelizations">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">ID</th>
                                <th class="text-center" scope="col">Nome do evento</th>
                                <th class="text-center" scope="col">Status</th>
                                <th class="text-center" scope="col">Alcance</th>
                                <th class="text-center" scope="col">Data</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(evangelization, index) in evangelizations.data"
                                :key="index"
                                @click="goToEvangelizationPage(evangelization.id)"
                            >
                                <td class="text-center">
                                    {{ evangelization.id }}
                                </td>
                                <td class="text-center">
                                    {{ evangelization.name }}
                                </td>
                                <td class="text-center">
                                    <Badge :name="evangelization.status ? 'ACTIVATE' : 'CLOSED'" />
                                </td>
                                <td class="text-center">
                                    {{ evangelization.evangelized_people?.length || 0 }} pessoas
                                </td>
                                <td class="text-center">
                                    {{ evangelization.date_completion }}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="5">
                                    <Pagination
                                        :data="evangelizations.meta"
                                        reference-date="evangelizations"
                                        identification="Evangelizações"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
