<script setup>
import { defineProps, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    evangelization: Object,
});

const form = useForm({
    name: props.evangelization.name,
    description: props.evangelization.description,
    date_completion: props.evangelization.date_completion_gross,
    status: props.evangelization.status,
    identification_photo: `${props.evangelization.identification_photo}`,
});

const update = () => form.put(`/dashboard/evangelizations/${props.evangelization.id}`);

const evangelizationEditModalCloseButton = ref(null);

const destroy = () => {
    evangelizationEditModalCloseButton.value.click();
    router.delete(`/dashboard/evangelizations/${props.evangelization.id}`);
};

const inputRef = ref(null);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.identification_photo = e.target.result;
    };
    reader.readAsDataURL(blob);
};
</script>

<template>
    <Dashboard page-title="Nova evangelização">
        <Breadcrumb
            current-page-name="Nova evangelização"
            :link-list="[
                {
                    previous_page_url: 'evangelizations',
                    previous_page_name: 'Evangelizações',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/evangelizations/${evangelization.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#evangelizationEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="d-flex justify-content-center">
                <div class="position-relative">
                    <img
                        :src="form.identification_photo"
                        alt="Identification Photo"
                        class="upload-box img-fluid shadow-sm"
                    />
                    <div class="position-absolute bottom-0 end-0 m-3">
                        <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#photoUpdateModal"
                            class="btn btn-edit shadow"
                        >
                            <font-awesome-icon class="text-white" :icon="['fas', 'pen']" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label for="name" class="form-label"> Nome da evangelização </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Aviva ilha"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="status" class="form-label">Situação</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option selected value="1">Ativa</option>
                    <option value="0">Encerrada</option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="date_completion" class="form-label">Data de início</label>
                <input
                    id="date"
                    v-model="form.date_completion"
                    type="date"
                    autocomplete="off"
                    class="form-control"
                    required
                />
            </div>

            <div class="col-md-12">
                <label for="description" class="form-label"> Descrição </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                    name="description"
                    placeholder="Forneça um breve resumo dessa evangelização"
                    rows="5"
                />

                <small v-if="form.errors.description" class="text-danger mt-3">
                    {{ form.errors.description }}
                </small>
            </div>
            <input type="file" ref="inputRef" class="file-input d-none" />
        </Form>
        <PhotoEditModal
            v-if="evangelization.identification_photo"
            title="Atualizar foto de perfil"
            id="photoUpdateModal"
            @image-resized="handleImageResized"
        />
        <ConfirmationModal
            modal_id="evangelizationEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="evangelizationEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
