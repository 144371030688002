<script setup>
import { onMounted } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({ permissions: Object });

const form = useForm({
    name: null,
    display_name: null,
    description: null,
    permissions: [],
});

const store = () => form.post('/dashboard/roles');

onMounted(() => {
    form.permissions = props.permissions.map((permission) => permission.id);
});
</script>

<template>
    <Dashboard page-title="Nova função">
        <Breadcrumb
            current-page-name="Nova função"
            :link-list="[
                {
                    previous_page_url: 'roles',
                    previous_page_name: 'Funções',
                },
            ]"
        />
        <h4 class="my-3">Nova função</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-6">
                <label for="name" class="form-label"> Nome da função </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="create-member"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-6">
                <label for="name" class="form-label"> Nome legível da função </label>

                <input
                    id="name"
                    v-model="form.display_name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Criar membro"
                    required
                />
                <small v-if="form.errors.display_name" class="text-danger mt-3">
                    {{ form.errors.display_name }}
                </small>
            </div>

            <div class="col-md-12">
                <label for="description" class="form-label">Descrição da função</label>
                <input
                    type="text"
                    class="form-control"
                    id="description"
                    description="description"
                    placeholder="Descrição da função"
                    v-model="form.description"
                    autocomplete="off"
                    required
                />
                <small class="text-danger mt-3" v-if="form.errors.description">{{
                    form.errors.description
                }}</small>
            </div>

            <ul class="list-group overflow-auto normal-listing p-2">
                <li
                    class="list-group-item p-3 d-flex align-items-center"
                    v-for="(permission, index) in permissions"
                    :key="index"
                >
                    <input
                        class="form-check-input me-1 mt-0"
                        type="checkbox"
                        :value="permission.id"
                        :id="'checkBoxPermission' + index"
                        v-model="form.permissions"
                    />
                    <label
                        class="form-check-label stretched-link ms-3"
                        :for="'checkBoxPermission' + index"
                        style="cursor: pointer"
                        >{{ permission.display_name }}</label
                    >
                </li>
            </ul>
        </Form>
    </Dashboard>
</template>
