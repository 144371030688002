<script setup>
import { onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    role: Object,
    allPermissions: Object,
    rolePermissions: Object,
});

const form = useForm({
    display_name: props.role.display_name,
    description: props.role.description,
    permissions: [],
});

const update = () => router.put(`/dashboard/roles/${props.role.id}`, form);

onMounted(() => {
    form.permissions = props.rolePermissions.map((role) => role.id);
});

const roleEditModalCloseButton = ref(null);

const destroy = () => {
    roleEditModalCloseButton.value.click();
    router.delete(`/dashboard/roles/${props.role.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar função">
        <Breadcrumb
            current-page-name="Editar função"
            :link-list="[
                {
                    previous_page_url: 'roles',
                    previous_page_name: 'Funções',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link :href="`/dashboard/roles/${role.id}`" class="btn btn-success btn-add me-2">
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#roleEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-sm-4">
                <label for="display_name" class="form-label">Nome legível da função</label>
                <input
                    type="text"
                    id="display_name"
                    class="form-control"
                    v-model="form.display_name"
                    autocomplete="off"
                    required
                />
                <small class="text-danger" v-if="form.errors.display_name">{{
                    form.errors.display_name
                }}</small>
            </div>
            <div class="col-sm-8">
                <label for="description" class="form-label">Nome legível da função</label>
                <input
                    type="text"
                    id="description"
                    class="form-control"
                    v-model="form.description"
                    autocomplete="off"
                    required
                />
                <small class="text-danger" v-if="form.errors.description">{{
                    form.errors.description
                }}</small>
            </div>

            <ul class="list-group overflow-auto p-2 normal-listing">
                <h5 class="mb-4 font-bold">Permissões atribuídas a esta função</h5>
                <li
                    class="list-group-item p-3 d-flex align-items-center"
                    v-for="(permission, index) in allPermissions"
                    :key="index"
                >
                    <input
                        class="form-check-input me-1 mt-0"
                        type="checkbox"
                        :value="permission.id"
                        :id="'checkBoxPermission' + permission.id"
                        :checked="form.permissions.includes(permission.id) ? true : false"
                        v-model="form.permissions"
                    />
                    <label
                        class="form-check-label stretched-link ms-3"
                        :for="'checkBoxPermission' + permission.id"
                        style="cursor: pointer"
                        >{{ permission.display_name }} - {{ permission.description }}</label
                    >
                </li>
            </ul>
        </Form>
        <ConfirmationModal
            modal_id="roleEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="roleEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
