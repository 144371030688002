<script setup>
import { ref } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    user: Object,
    status: Object,
    userRole: Object,
    roles: Object,
});

const form = useForm({
    _method: 'put',
    status: props.user.status,
    name: props.user.name,
    email: props.user.email,
    role_id: props.userRole.id || 1,
    profile_image_url: `${props.user.profile_image_url}`,
});

const inputRef = ref(null);

const store = () => form.post(`/dashboard/users/${props.user.id}`);

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.profile_image_url = e.target.result;
    };
    reader.readAsDataURL(blob);
};
</script>

<template>
    <Dashboard page-title="Editar dados do usuário">
        <Breadcrumb
            :current-page-name="user.name"
            :link-list="[
                {
                    previous_page_url: 'users',
                    previous_page_name: 'Usuários',
                },
            ]"
        />

        <div id="edit-user">
            <div class="row mt-3 mb-3">
                <div class="col d-flex justify-content-between align-items-center">
                    <h4 class="mb-0">Editar dados</h4>
                    <Link :href="`/dashboard/users/${user.id}`" class="btn btn-success btn-add">
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Atualizar dados"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.profile_image_url"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon class="text-white" :icon="['fas', 'pen']" />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="name" class="form-label"> Nome do usuário </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Micheline Paiva"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="email" class="form-label"> Email </label>

                    <input
                        id="email"
                        v-model="form.email"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Micheline Paiva"
                        required
                    />
                    <small v-if="form.errors.email" class="text-danger mt-3">
                        {{ form.errors.email }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="type" class="form-label">Status</label>
                    <select id="type" v-model="form.status" class="form-select">
                        <option v-for="(item, index) in status" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6 col-md-3">
                    <label for="function" class="form-label">Função</label>
                    <select
                        class="form-select"
                        name="function"
                        id="function"
                        v-model="form.role_id"
                    >
                        <option v-for="(role, index) in roles" :key="index" :value="role.id">
                            {{ role.display_name }}
                        </option>
                    </select>
                    <small class="text-danger mt-3" v-if="form.errors.role">{{
                        form.errors.role
                    }}</small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>
            <PhotoEditModal
                v-if="user.profile_image_url"
                title="Atualizar foto de perfil"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
