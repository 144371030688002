<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    pageTitle: String,
    baseRoute: String,
});

const folderName = computed(() =>
    props.baseRoute
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
);
</script>

<template>
    <div
        class="d-flex justify-content-between align-items-center flex-wrap grid-margin page-navigation-header mb-4"
    >
        <div>
            <h4 class="mb-md-0">
                {{ pageTitle }}
            </h4>
        </div>
        <div class="d-flex align-items-center flex-wrap text-nowrap">
            <Link
                :href="`/dashboard/${baseRoute}`"
                :class="{
                    active: $page.component === `Dashboard/Pages/${folderName}/Index`,
                }"
                class="me-2 mb-2 mb-md-0"
            >
                <font-awesome-icon class="me-2" :icon="['fas', 'bars-staggered']" />
                Listagem
            </Link>
            <Link
                :href="`/dashboard/${baseRoute}/create`"
                :class="{
                    active: $page.component === `Dashboard/Pages/${folderName}/Create`,
                }"
                class="mb-2 mb-md-0"
            >
                <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                Cadastrar
            </Link>
        </div>
    </div>
</template>
