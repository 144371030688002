<script setup>
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import AreaChart from '@/Pages/Dashboard/Graphics/AreaChart.vue';

import EventCard from '@/Pages/Dashboard/Pages/Events/Components/EventCard.vue';
import WaterBaptismCard from '../Pages/WaterBaptisms/Components/WaterBaptismCard.vue';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    visitorsLastThirtyDays: Number,
    newBirthsLastThirtyDays: Number,
    officialMembers: Number,
    totalFamilies: Number,
    allVisitors: Object,
    allCensus: Object,
    lastVisitors: Object,
    lastNewBirths: Object,
    waterBaptism: Object,
    lastEvents: Object,
    birthdaysMonth: Object,
});

const goToNewBirthPage = (memberId) => router.get(`/dashboard/new-births/${memberId}`);
const goToVisitorPage = (memberId) => router.get(`/dashboard/visitors/${memberId}`);
const goToMemberPage = (memberId) => router.get(`/dashboard/members/${memberId}`);

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}/${month}`;
};
</script>

<template>
    <Dashboard page-title="Visão geral">
        <div class="mt-2 mx-0" id="home">
            <h3 class="font-bold mb-3">Visão geral</h3>
            <div class="row" id="main-cards">
                <div class="col-sm-3 col-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Visitantes</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ visitorsLastThirtyDays }}
                                <span class="fs-12">últimos 30 dias</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/visitors">Ver visitantes</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span class="px-3 py-2 bg-success bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'person-shelter']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Conversões</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ newBirthsLastThirtyDays }}
                                <span class="fs-12">últimos 30 dias</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/new-births">Ver registros</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span class="px-3 py-2 bg-primary bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'person-praying']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6 mt-3 mt-sm-0">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Membros</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ officialMembers }} <span class="fs-12">cadastrados</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/members?type=MEMBER">Ver membros</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span class="px-3 py-2 bg-warning bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'users']" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 col-6 mt-3 mt-sm-0">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title text-secondary font-bold">Famílias</h5>
                            <h4 class="font-bold mb-lg-3">
                                {{ totalFamilies }} <span class="fs-12">cadastradas</span>
                            </h4>
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-8">
                                    <Link href="/dashboard/families">Ver famílias</Link>
                                </div>
                                <div class="col-lg-4 text-end mt-3 mt-md-0">
                                    <span class="px-3 py-2 bg-secondary bg-opacity-25 rounded-2">
                                        <font-awesome-icon :icon="['fas', 'people-roof']"
                                    /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-none d-sm-block">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <div class="card p-3 graphics">
                            <AreaChart
                                :data="allVisitors"
                                title="Ultimas visitas"
                                description="novos visitantes em"
                                :options="[
                                    { value: 'last_14_days', name: 'Últimos 14 dias' },
                                    { value: 'last_month', name: 'Último mês' },
                                ]"
                            />
                        </div>
                    </div>
                    <div class="col-md-6 mt-sm-3 mt-md-0">
                        <div class="card p-3 graphics">
                            <AreaChart
                                :data="allCensus"
                                title="Últimos censos"
                                description="pessoas no censo de"
                                :options="[
                                    { value: 'last_14_days', name: 'Últimos 14 dias' },
                                    { value: 'last_month', name: 'Último mês' },
                                ]"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="search-form-card overflow-auto" id="last-sales">
                                <div
                                    class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                                >
                                    <span class="font-bold text-uppercase">Últimos visitantes</span>
                                </div>
                                <hr class="my-0" />
                                <div class="py-0">
                                    <div class="table-responsive" v-if="lastVisitors?.data.length">
                                        <table
                                            class="table align-middle table-hover"
                                            id="table-home-visitors"
                                        >
                                            <thead>
                                                <tr>
                                                    <th class="text-center" scope="col">Nome</th>
                                                    <th class="text-center" scope="col">
                                                        Programação
                                                    </th>
                                                    <th class="text-center" scope="col">Crente</th>
                                                    <th class="text-center" scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(member, index) in lastVisitors.data"
                                                    :key="index"
                                                    @click="goToVisitorPage(member.id)"
                                                    :class="{
                                                        'table-warning': member.is_event_today,
                                                    }"
                                                >
                                                    <td class="text-center">
                                                        {{ member.member.clean_name }}
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="member.schedule_name">{{
                                                            member.schedule_name
                                                        }}</span>

                                                        <span
                                                            v-else
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="top"
                                                            data-bs-title="Dado do SGI antigo"
                                                        >
                                                            <font-awesome-icon
                                                                :icon="['fas', 'minus']"
                                                            />
                                                        </span>
                                                    </td>
                                                    <td class="text-center">
                                                        <Badge
                                                            :name="
                                                                member.member.believer
                                                                    ? 'YES'
                                                                    : 'NOT'
                                                            "
                                                        />
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="member.is_event_today">
                                                            <span class="badge text-bg-primary p-2"
                                                                >Hoje</span
                                                            >
                                                        </span>
                                                        <span v-else>
                                                            {{ member.created_at }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div v-else class="text-center p-4">
                                        Nenhum visitante encontrado
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2 d-flex align-items-center">
                <div class="col-lg-6 col-12" v-if="waterBaptism">
                    <div class="row">
                        <WaterBaptismCard :data="[waterBaptism]" />
                    </div>
                </div>
                <div :class="{ 'col-lg-6': waterBaptism, 'col-lg-12': !waterBaptism }">
                    <div class="search-form-card overflow-auto" id="last-sales">
                        <div
                            class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                        >
                            <span class="font-bold text-uppercase">Últimas conversões</span>
                        </div>
                        <hr class="my-0" />
                        <div class="py-0">
                            <div class="table-responsive" v-if="lastNewBirths?.data.length">
                                <table class="table align-middle table-hover">
                                    <thead>
                                        <tr>
                                            <th class="text-center" scope="col">
                                                Nome do convertido
                                            </th>
                                            <th class="text-center" scope="col">Programação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(member, index) in lastNewBirths.data"
                                            :key="index"
                                            @click="goToNewBirthPage(member.id)"
                                            :class="{
                                                'table-warning': member.is_event_today,
                                            }"
                                        >
                                            <td class="text-center">
                                                {{ member.member.clean_name }}
                                            </td>
                                            <td class="text-center">
                                                <span v-if="member.schedule_name">{{
                                                    member.schedule_name
                                                }}</span>

                                                <span
                                                    v-else
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    data-bs-title="Dado do SGI antigo"
                                                >
                                                    <font-awesome-icon :icon="['fas', 'minus']" />
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="text-center p-4">Nenhuma conversão encontrada</div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />

            <div v-if="lastEvents?.data.length">
                <div class="row mb-3">
                    <div class="col d-flex justify-content-between align-items-center">
                        <h4 class="mb-0">Eventos recentes</h4>
                        <Link :href="`/dashboard/events`" class="btn btn-primary btn-add">
                            <font-awesome-icon class="me-2" :icon="['fas', 'bars-staggered']" />
                            Ver todos
                        </Link>
                    </div>
                </div>
                <div class="row">
                    <EventCard
                        v-for="(item, index) in lastEvents.data"
                        :key="index"
                        :event="item"
                    />
                </div>
            </div>

            <div class="row d-flex align-items-center">
                <div class="col-lg-6 mb-3 mb-lg-0">
                    <div class="search-form-card overflow-auto" id="last-sales">
                        <div
                            class="card-header d-flex align-items-center justify-content-between bg-white p-3"
                        >
                            <span class="font-bold text-uppercase">Aniversariantes do mês</span>
                        </div>
                        <hr class="my-0" />
                        <div class="py-0">
                            <div class="table-responsive" v-if="birthdaysMonth.length">
                                <table class="table align-middle table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome do membro</th>
                                            <th class="text-center" scope="col">Data</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(member, index) in birthdaysMonth"
                                            :key="index"
                                            @click="goToMemberPage(member.id)"
                                            :class="{
                                                'table-warning': member.is_event_today,
                                            }"
                                        >
                                            <td>
                                                <img
                                                    class="rounded-circle me-3"
                                                    width="30"
                                                    :src="member.identification_photo"
                                                    alt=""
                                                />
                                                <span>
                                                    {{ member.clean_name }}
                                                </span>
                                            </td>
                                            <td class="text-center">
                                                {{ formatDate(member.birth_date) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-else class="text-center p-4">
                                Nenhum aniversariante encontrado
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 px-4">
                    <div class="row">
                        <div class="card mb-3">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Ministros</h5>
                                <p class="card-text font-regular">
                                    Veja os minitros da nossa igreja e suas ultimas atividades
                                </p>
                                <Link
                                    href="/dashboard/ministers"
                                    class="btn btn-success btn-sm text-white font-bold"
                                    >Ver ministros</Link
                                >
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title font-bold">Dirigentes</h5>
                                <p class="card-text font-regular">
                                    Veja os dirigentes da nossa igreja e os últimos eventos que eles
                                    dirigiram
                                </p>
                                <Link
                                    href="/dashboard/leaders"
                                    class="btn btn-primary btn-sm text-white font-bold"
                                >
                                    Ver dirigentes</Link
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
