import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faEye,
    faEyeSlash,
    faChalkboardUser,
    faBars,
    faAt,
    faCloudArrowUp,
    faUserTie,
    faPlus,
    faMagnifyingGlass,
    faRightToBracket,
    faSliders,
    faBell,
    faChevronLeft,
    faChevronRight,
    faCalendar,
    faComments,
    faCircleExclamation,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faFilter,
    faEllipsis,
    faLockOpen,
    faFileExcel,
    faFilePdf,
    faCross,
    faLock,
    faMinus,
    faCopyright,
    faSort,
    faAngleRight,
    faUpLong,
    faDownLong,
    faEllipsisVertical,
    faLeftLong,
    faRightLong,
    faArrowDownAZ,
    faArrowDownZA,
    faUpRightAndDownLeftFromCenter,
    faPeopleGroup,
    faAngleDown,
    faPeopleLine,
    faBarsStaggered,
    faUserGear,
    faList,
    faArrowsRotate,
    faPersonPraying,
    faUserCheck,
    faHandshakeAngle,
    faUsersRectangle,
    faArrowRightFromBracket,
    faPersonShelter,
    faPersonDrowning,
    faPersonRays,
    faHotel,
    faUsersBetweenLines,
    faBuildingUser,
    faCircleCheck,
    faPenToSquare,
    faCircleXmark,
    faUsers,
    faRightFromBracket,
    faUserLock,
    faClock,
    faChurch,
    faChildren,
    faArrowUpRightFromSquare,
    faLandmark,
    faPeopleRoof,
    faChartColumn,
    faCirclePlus,
    faArrowUpFromBracket,
    faPen,
    faUsersLine,
    faUser,
    faLayerGroup,
    faUpRightFromSquare,
    faGear,
} from '@fortawesome/free-solid-svg-icons';

import { faYoutube, faInstagram, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';

library.add(
    faEllipsisVertical,
    faCircleExclamation,
    faAngleRight,
    faCross,
    faFileExcel,
    faClock,
    faRightFromBracket,
    faCopyright,
    faWhatsapp,
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faFilePdf,
    faArrowUpFromBracket,
    faUpRightFromSquare,
    faCirclePlay,
    faUsersLine,
    faUsers,
    faCloudArrowUp,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faMinus,
    faUpLong,
    faDownLong,
    faLeftLong,
    faRightLong,
    faPenToSquare,
    faChartColumn,
    faArrowDownZA,
    faUsersBetweenLines,
    faArrowDownAZ,
    faChevronRight,
    faLockOpen,
    faLock,
    faChevronLeft,
    faEllipsis,
    faSort,
    faFilter,
    faCirclePlus,
    faYoutube,
    faUpRightAndDownLeftFromCenter,
    faBuildingUser,
    faPeopleLine,
    faPlus,
    faList,
    faBell,
    faGear,
    faPen,
    faCircleCheck,
    faCircleXmark,
    faArrowRightFromBracket,
    faBarsStaggered,
    faUserLock,
    faUserTie,
    faChalkboardUser,
    faUserGear,
    faComments,
    faAngleDown,
    faBars,
    faCalendar,
    faUser,
    faEye,
    faPeopleGroup,
    faAt,
    faUserCheck,
    faEyeSlash,
    faSliders,
    faRightToBracket,
    faMagnifyingGlass,
    faPersonShelter,
    faPersonPraying,
    faPersonDrowning,
    faPersonRays,
    faChurch,
    faUsersRectangle,
    faHotel,
    faPeopleRoof,
    faChildren,
    faLandmark,
    faHandshakeAngle,
    faLayerGroup,
    faInstagram,
    faFacebook
);
