<script setup>
import { defineProps, computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    schedule: Object,
});

const statusIcon = computed(() => (props.schedule.active ? 'circle-check' : 'circle-xmark'));
const statusText = computed(() => (props.schedule.active ? 'Ativa' : 'Desativada'));
</script>

<template>
    <div class="col-lg-6 mb-3">
        <div class="card schedule-card">
            <img src="/img/schedules/default_banner.jpg" class="card-img-top" alt="" />
            <div class="position-absolute top-0 end-0 m-3">
                <a
                    class="btn btn-light shadow"
                    href="#"
                    role="button"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    :data-bs-title="statusText"
                >
                    <font-awesome-icon class="text-success fs-5" :icon="['fas', statusIcon]" />
                </a>
            </div>

            <div class="card-body">
                <h5 class="card-title">
                    {{ schedule.name }}
                </h5>
                <p class="card-text">
                    {{ schedule.description }}
                </p>
            </div>

            <div class="card-footer d-flex justify-content-between bg-white">
                <Link
                    class="btn btn-light"
                    :href="`/dashboard/events/create/${schedule.id}`"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    data-bs-title="Adicionar evento"
                    role="button"
                >
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </Link>

                <Link
                    :href="`/dashboard/schedules/${schedule.id}`"
                    class="btn btn-light"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    data-bs-title="Acessar"
                >
                    <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" />
                </Link>
            </div>
        </div>
    </div>
</template>
