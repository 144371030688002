<script>
import { ref } from 'vue';

export default function usePreviewImage() {
    const imageUrl = ref(null);
    const imageSizeInMB = ref(null);

    function previewImage(e) {
        const image = e.target.files[0];
        imageUrl.value = URL.createObjectURL(image);

        const fileSizeInBytes = image.size;
        imageSizeInMB.value = (fileSizeInBytes / (1024 * 1024)).toFixed(2);
    }

    return {
        imageUrl,
        imageSizeInMB,
        previewImage,
    };
}
</script>
