<script setup>
import { useForm } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    typesIndications: Object,
    events: Object,
    sexs: Object,
});

const form = useForm({
    name: null,
    phone_number: null,
    met_church: props.typesIndications[0].value,
    event_id: props.events[0]?.id || null,
    believer: 0,
    sex: props.sexs[0].value,
});

const store = () => form.post('/dashboard/visitors');
</script>

<template>
    <Dashboard page-title="Novo visitante">
        <Breadcrumb
            current-page-name="Novo visitante"
            :link-list="[
                {
                    previous_page_url: 'visitors',
                    previous_page_name: 'Visitantes',
                },
            ]"
        />
        <h4 class="my-3">Novo visitante</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-7">
                <label for="name" class="form-label"> Nome do visitante </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="João Pedro de Souza"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-5">
                <label for="phone_number" class="form-label"> Telefone / Whatsapp </label>
                <small class="optional ms-2">(opcional)</small>

                <input
                    id="phone_number"
                    v-model="form.phone_number"
                    v-maska
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="(00) 00000-0000"
                    data-maska="(##) #####-####"
                />
                <small v-if="form.errors.phone_number" class="text-danger mt-3">
                    {{ form.errors.phone_number }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Como conheceu a igreja</label>
                <select
                    id="type"
                    v-model="form.met_church"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option
                        v-for="(item, index) in typesIndications"
                        :key="index"
                        :value="item.value"
                    >
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-6">
                <label for="type" class="form-label">Evento relacionado</label>
                <select
                    id="type"
                    v-model="form.event_id"
                    class="form-select form-select"
                    aria-label="Small select example"
                    required
                >
                    <option v-for="(item, index) in events" :key="index" :value="item.id">
                        {{ item.schedule.name }} - {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Sexo</label>
                <select
                    id="type"
                    v-model="form.sex"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.believer"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                        Já é crente?
                    </label>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>
