<script setup>
import { defineProps, ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    typesIndications: Object,
    allEvents: Object,
    sexs: Object,
    visitor: Object,
    currentEvent: Object,
});

const form = useForm({
    name: props.visitor.member.name,
    met_church: props.visitor.met_church,
    event_id: props.currentEvent.id,
    believer: props.visitor.member.believer ? true : false,
    sex: props.visitor.member.sex,
    phone_number: props.visitor.member.phone_number?.phone_number || null,
});

const update = () => form.put(`/dashboard/visitors/${props.visitor.id}`);

const visitorEditModalCloseButton = ref(null);

const destroy = () => {
    visitorEditModalCloseButton.value.click();
    router.delete(`/dashboard/visitors/${props.visitor.id}`);
};
</script>

<template>
    <Dashboard :page-title="visitor.member.name">
        <Breadcrumb
            :current-page-name="visitor.member.name"
            :link-list="[
                {
                    previous_page_url: 'visitors',
                    previous_page_name: 'Visitantes',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/visitors/${visitor.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#visitorEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-7">
                <label for="name" class="form-label"> Nome do visitante </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="João Pedro de Souza"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-5">
                <label for="phone_number" class="form-label"> Telefone / Whatsapp </label>
                <small class="optional ms-2">(opcional)</small>

                <input
                    id="phone_number"
                    v-model="form.phone_number"
                    v-maska
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="(00) 00000-0000"
                    data-maska="(##) #####-####"
                />
                <small v-if="form.errors.phone_number" class="text-danger mt-3">
                    {{ form.errors.phone_number }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Como conheceu a igreja</label>
                <select
                    id="type"
                    v-model="form.met_church"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option
                        v-for="(item, index) in typesIndications"
                        :key="index"
                        :value="item.value"
                    >
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-6">
                <label for="type" class="form-label">Evento relacionado</label>
                <select
                    id="type"
                    v-model="form.event_id"
                    class="form-select form-select"
                    aria-label="Small select example"
                    required
                >
                    <option v-for="(item, index) in allEvents" :key="index" :value="item.id">
                        {{ item.schedule.name }} - {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Sexo</label>
                <select
                    id="type"
                    v-model="form.sex"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.believer"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                        Já é crente?
                    </label>
                </div>
            </div>
        </Form>
        <ConfirmationModal
            modal_id="visitorEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="visitorEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
