<script setup>
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';

const props = defineProps({
    errors: Object,
    twoFactorQrCodeSvg: String,
    twoFactorSecret: String,
    user: Object,
});

const form = reactive({ code: null });

const confirm = () => router.post('/user/confirmed-two-factor-authentication', { code: form.code });

const disable = () =>
    router.delete('/user/two-factor-authentication', {
        onSuccess: () => (form.code = ''),
    });
const enable = () =>
    router.post('/user/two-factor-authentication', {
        onSuccess: () => (form.code = ''),
    });
</script>

<template>
    <Dashboard page_title="2 Fatores">
        <Breadcrumb
            currentPageName="2 fatores"
            :linkList="[{ previous_page_url: 'profile', previous_page_name: 'Perfil' }]"
        />
        <h4>2 fatores</h4>
        <div class="row mt-4 mx-0">
            <div class="bg-white border border-1 rounded-3 p-3">
                <div class="row">
                    <div class="overflow-auto" id="showuser">
                        <div class="row p-3">
                            <div v-if="user.two_factor_secret">
                                <div v-if="!user.two_factor_confirmed_at">
                                    <p class="my-3 text-justify">
                                        Para concluir a habilitação da autenticação de dois fatores,
                                        digitalize o seguinte código QR usando o aplicativo
                                        autenticador do seu telefone ou digite a chave de
                                        configuração e forneça o código OTP gerado.
                                    </p>

                                    <div
                                        class="d-flex justify-content-center my-4"
                                        v-html="twoFactorQrCodeSvg"
                                    ></div>

                                    <p class="my-3 text-center font-regular">
                                        Chave de configuração:
                                        <span class="font-bold">{{ twoFactorSecret }}</span>
                                    </p>

                                    <form
                                        class="col-lg-12 shadow-none mt-4"
                                        @submit.prevent="confirm"
                                    >
                                        <div class="text-center">
                                            <div class="col-lg-6 mx-auto">
                                                <input
                                                    id="code"
                                                    type="code"
                                                    class="form-control"
                                                    autocomplete="off"
                                                    placeholder="Por favor, insira o código recebido"
                                                    v-model="form.code"
                                                    required
                                                    maxlength="50"
                                                />
                                                <small
                                                    class="text-danger mt-3"
                                                    v-if="errors.confirmTwoFactorAuthentication"
                                                    >Código informado é inválido, tente
                                                    novamente.</small
                                                >
                                            </div>
                                            <button
                                                class="btn btn-outline-success fw-semibold mt-3"
                                                type="submit"
                                            >
                                                Ativar 2 fatores
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div v-else>
                                    <div class="alert alert-secondary" role="alert">
                                        A autenticação de 2 fatores está ativada em sua conta
                                    </div>
                                    <form
                                        class="mt-3 border-0 shadow-none"
                                        id="changeFormRecoveryCodes"
                                        @submit.prevent="disable"
                                    >
                                        <button
                                            class="btn btn-outline-success fw-semibold"
                                            type="submit"
                                        >
                                            Desativar 2 fatores
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div v-else>
                                <img
                                    class="d-block mx-auto"
                                    src="/img/security.png"
                                    width="150"
                                    alt=""
                                />

                                <p class="text-justify mt-4 font-regular">
                                    Ao habilitar a autenticação de dois fatores, você será
                                    solicitado a fornecer um token seguro e aleatório durante o
                                    processo de autenticação. Esse mecanismo de segurança adicional
                                    visa garantir a proteção de suas informações, exigindo que você
                                    forneça uma segunda forma de autenticação além da sua senha
                                    convencional. O token gerado aleatoriamente é único e
                                    temporário, tornando ainda mais difícil para invasores obterem
                                    acesso não autorizado à sua conta.
                                </p>

                                <form
                                    class="mt-3 border-0 shadow-none"
                                    id="changeFormRecoveryCodes"
                                    @submit.prevent="enable"
                                >
                                    <button
                                        class="btn btn-outline-success font-semibold float-end"
                                        type="submit"
                                    >
                                        Ativar 2 fatores
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
