<script setup>
import { useForm } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const form = useForm({
    name: null,
    description: null,
    active: 1,
});

const store = () => form.post('/dashboard/schedules');
</script>

<template>
    <Dashboard page-title="Nova programação">
        <Breadcrumb
            current-page-name="Nova programação"
            :link-list="[
                {
                    previous_page_url: 'schedules',
                    previous_page_name: 'Programações',
                },
            ]"
        />
        <h4 class="my-3">Nova programação</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-8">
                <label for="name" class="form-label"> Nome da programação </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Culto de celebração"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-4">
                <label for="active" class="form-label">Situação</label>
                <select
                    id="active"
                    v-model="form.active"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option selected value="1">Ativo</option>
                    <option value="0">Desativado</option>
                </select>
            </div>

            <div class="col-md-12">
                <label for="description" class="form-label"> Descrição </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="description"
                    v-model="form.description"
                    type="text"
                    class="form-control"
                    name="description"
                    placeholder="Forneça um breve resumo dessa programação"
                    rows="5"
                />

                <small v-if="form.errors.description" class="text-danger mt-3">
                    {{ form.errors.description }}
                </small>
            </div>
        </Form>
    </Dashboard>
</template>
