<script setup>
import { ref } from 'vue';
import { Head, useForm } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

const props = defineProps({
    evangelization: Object,
    typesNewBirths: Object,
    sexs: Object,
    existing: {
        type: Boolean,
        default: true,
    },
});

const form = useForm({
    name: null,
    phone_number: null,
    address: null,
    observations: null,
    age: null,
    new_birth_type: props?.typesNewBirths?.[0]?.value || null,
    sex: props?.sexs?.[0]?.value || null,
    evangelization_id: props.evangelization?.id || null,
});

const message = ref(null);

const store = () =>
    form.post('/evangelizacoes', {
        onSuccess: () => {
            form.reset();
            message.value = 'Evangelizado(a) cadastrado com sucesso';
        },
    });
</script>

<template>
    <Head title="Evangelização" />
    <div class="vh-100 d-flex justify-content-center align-items-center" id="evangelization">
        <div class="col-md-7 col-lg-6 col-xxl-4">
            <div class="card border p-sm-5 p-4 rounded-3">
                <div class="row m-0 mt-5 mt-md-0" v-if="existing">
                    <form class="form-card border-0 shadow-none" @submit.prevent="store">
                        <img
                            class="mx-auto d-block"
                            width="120"
                            :src="evangelization.identification_photo"
                            alt=""
                        />
                        <hr />
                        <div
                            class="alert alert-warning alert-dismissible fade show"
                            role="alert"
                            v-if="message"
                        >
                            {{ message }}
                            <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                            ></button>
                        </div>

                        <div class="row g-3">
                            <div class="col-12">
                                <label for="name" class="form-label">Nome do Evangelizado(a)</label>
                                <input
                                    placeholder="João Pedro de Souza"
                                    type="text"
                                    v-model="form.name"
                                    class="form-control"
                                    name="name"
                                    id="name"
                                    autocomplete="on"
                                    aria-describedby="nameHelp"
                                    required
                                />
                                <small v-if="form.errors.name" class="text-danger mt-3">
                                    {{ form.errors.name }}
                                </small>
                            </div>
                            <div class="col-sm-6">
                                <label for="new_birth_type" class="form-label">Tipo da ação</label>
                                <select
                                    id="new_birth_type"
                                    v-model="form.new_birth_type"
                                    class="form-select"
                                    required
                                >
                                    <option
                                        v-for="(item, index) in typesNewBirths"
                                        :key="index"
                                        :value="item.value"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-sm-6">
                                <label for="phone_number" class="form-label"> Contato </label>
                                <small class="optional ms-2">(opcional)</small>

                                <input
                                    id="phone_number"
                                    v-model="form.phone_number"
                                    v-maska
                                    type="text"
                                    autocomplete="off"
                                    class="form-control"
                                    placeholder="(00) 00000-0000"
                                    data-maska="(##) #####-####"
                                />
                                <small v-if="form.errors.phone_number" class="text-danger mt-3">
                                    {{ form.errors.phone_number }}
                                </small>
                            </div>
                            <div class="col-6">
                                <label for="sex" class="form-label">Sexo</label>
                                <select id="sex" v-model="form.sex" class="form-select" required>
                                    <option
                                        v-for="(item, index) in sexs"
                                        :key="index"
                                        :value="item.value"
                                    >
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-6">
                                <label for="age" class="form-label">Idade</label>
                                <input
                                    placeholder="30"
                                    type="number"
                                    v-model="form.age"
                                    class="form-control"
                                    name="age"
                                    id="age"
                                    autocomplete="on"
                                    aria-describedby="ageHelp"
                                    required
                                />
                                <small v-if="form.errors.age" class="text-danger mt-3">
                                    {{ form.errors.age }}
                                </small>
                            </div>
                            <div class="col-12">
                                <label for="address" class="form-label">Endereço</label>
                                <small class="optional ms-2">(opcional)</small>
                                <input
                                    placeholder=" Rua Juscelino Kubitschek"
                                    type="text"
                                    v-model="form.address"
                                    class="form-control"
                                    name="address"
                                    id="address"
                                    autocomplete="on"
                                    aria-describedby="addressHelp"
                                />
                                <small v-if="form.errors.address" class="text-danger mt-3">
                                    {{ form.errors.address }}
                                </small>
                            </div>

                            <div class="col-12">
                                <label for="observations" class="form-label"> Observações </label>
                                <small class="optional ms-2">(opcional)</small>

                                <textarea
                                    id="observations"
                                    v-model="form.observations"
                                    type="text"
                                    class="form-control"
                                    name="observations"
                                    placeholder="Caso necessário, forneca alguma observação."
                                    rows="3"
                                />

                                <small v-if="form.errors.observations" class="text-danger mt-3">
                                    {{ form.errors.observations }}
                                </small>
                            </div>
                        </div>

                        <button
                            class="btn btn-success mt-3 font-bold text-white float-end"
                            type="submit"
                            :disabled="form.processing"
                        >
                            <span
                                v-if="form.processing"
                                class="spinner-border spinner-border-sm me-2"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            Cadastrar
                        </button>
                    </form>
                </div>
                <div v-else>
                    <p class="p-3 bg-success bg-opacity-10 border my-3 rounded-3 text-center">
                        Não existe nenhuma evangelização ativa no momento.
                    </p>
                </div>
            </div>
            <div class="row mt-sm-3 mb-4 mb-sm-0">
                <small class="text-center font-regular copyright">
                    Verbo da Vida Paulo Afonso
                    <font-awesome-icon class="ms-2" :icon="['fas', 'copyright']" />
                </small>
            </div>
        </div>
    </div>
</template>
