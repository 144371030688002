<script setup>
import { defineProps, ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

const props = defineProps({
    sexs: Object,
    degree_familiaritys: Object,
    members: Object,
    family: Object,
});

const form = useForm({
    name: props.family.name,
    members: props.family.members,
    addresses: props.family.addresses,
});

function addAddress() {
    form.addresses.push({
        address: null,
        zipcode: null,
        neighborhood: null,
        city: null,
        number: null,
    });
}

const removeAddress = (index) => {
    form.addresses.splice(index, 1);
};

function addFamilyMember() {
    form.members.push({
        name: null,
        sex: props.sexs[0]?.value || null,
        degree_familiarity: props.degree_familiaritys[0]?.value || null,
        believer: false,
        member_id: '',
    });
}

const removeFamilyMember = (index) => {
    form.members.splice(index, 1);
};

const update = () => form.put(`/dashboard/families/${props.family.id}`);

async function fetchAddressByZipcode(index) {
    const zipcode = form.addresses[index].zipcode.replace('-', '');

    if (zipcode && zipcode.length === 8) {
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);
            if (!response.data.erro) {
                form.addresses[index].address = response.data.logradouro;
                form.addresses[index].neighborhood = response.data.bairro;
                form.addresses[index].city = response.data.localidade;
            } else {
                console.error('CEP não encontrado');
            }
        } catch (error) {
            console.error('Erro ao buscar o CEP:', error);
        }
    }
}

const familyEditModalCloseButton = ref(null);

const destroy = () => {
    familyEditModalCloseButton.value.click();
    router.delete(`/dashboard/families/${props.family.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar família">
        <Breadcrumb
            current-page-name="Editar família"
            :link-list="[
                {
                    previous_page_url: 'families',
                    previous_page_name: 'Famílias',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/families/${family.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#familyEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-12">
                <label for="name" class="form-label"> Nome da família </label>
                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Família Gomes"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <hr class="mb-0" />

            <div class="d-flex justify-content-between align-items-center mb-2">
                <legend class="col mb-0">Membros da família</legend>
                <button type="button" class="btn btn-primary" @click="addFamilyMember">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </button>
            </div>

            <div v-for="(member, index) in form.members" :key="index">
                <div class="row">
                    <div class="col-md-6">
                        <label for="member-name" class="form-label"> Nome do membro </label>
                        <input
                            :id="'member-name-' + index"
                            v-model="member.name"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Nome do membro"
                        />
                    </div>

                    <div class="col-md-3 mt-2 mt-lg-0">
                        <label :for="'sex-' + index" class="form-label">Sexo</label>
                        <select :id="'sex-' + index" v-model="member.sex" class="form-select">
                            <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-3 mt-2 mt-lg-0">
                        <label :for="'degree-' + index" class="form-label">Posição</label>
                        <select
                            :id="'degree-' + index"
                            v-model="member.degree_familiarity"
                            class="form-select"
                        >
                            <option
                                v-for="(item, index) in degree_familiaritys"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label :for="'member-id-' + index" class="form-label"
                            >Membro vinculado</label
                        >
                        <select
                            :id="'member-id-' + index"
                            v-model="member.member_id"
                            class="form-select"
                        >
                            <option value="">Não vinculado a nenhum membro</option>
                            <option v-for="(item, index) in members" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-12 mt-3">
                        <div class="form-check d-flex align-items-center form-switch">
                            <input
                                :id="'believer-' + index"
                                v-model="member.believer"
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                            />
                            <label class="form-check-label ms-3" :for="'believer-' + index">
                                Já é crente?
                            </label>
                        </div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="removeFamilyMember(index)"
                        >
                            Remover Membro
                        </button>
                    </div>
                </div>
                <hr class="mb-0" />
            </div>

            <hr class="mb-0" />

            <div class="d-flex justify-content-between align-items-center mb-2">
                <legend class="col mb-0">Endereços da família</legend>
                <button type="button" class="btn btn-primary" @click="addAddress">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                </button>
            </div>

            <div v-for="(address, index) in form.addresses" :key="index">
                <div class="row">
                    <div class="col-md-8">
                        <label for="address" class="form-label"> Endereço </label>
                        <input
                            :id="'address-' + index"
                            v-model="address.address"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Nome da rua"
                        />
                    </div>
                    <div class="col-md-4 mt-2 mt-sm-0">
                        <label for="zipcode" class="form-label"> CEP </label>
                        <input
                            v-maska
                            :id="'zipcode-' + index"
                            v-model="address.zipcode"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Número do CEP"
                            data-maska="#####-###"
                            @blur="fetchAddressByZipcode(index)"
                        />
                    </div>
                    <div class="col-md-4 mt-3">
                        <label for="neighborhood" class="form-label"> Bairro </label>
                        <input
                            :id="'neighborhood-' + index"
                            v-model="address.neighborhood"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Nome do bairro"
                        />
                    </div>
                    <div class="col-md-4 mt-3">
                        <label for="city" class="form-label"> Cidade </label>
                        <input
                            :id="'city-' + index"
                            v-model="address.city"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Nome da cidade"
                        />
                    </div>
                    <div class="col-md-4 mt-3">
                        <label for="number" class="form-label"> Número </label>
                        <input
                            :id="'number-' + index"
                            v-model="address.number"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Número da casa"
                        />
                    </div>
                    <div class="col-md-12 mt-3">
                        <button type="button" class="btn btn-danger" @click="removeAddress(index)">
                            Remover Endereço
                        </button>
                    </div>
                </div>
                <hr class="mb-0" />
            </div>
            <ConfirmationModal
                modal_id="familyEditModal"
                modal_title="Confirmação de remoção da família"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir a família {{ family.name }}?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="familyEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar família
                    </button>
                </template>
            </ConfirmationModal>
        </Form>
    </Dashboard>
</template>
