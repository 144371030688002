<template>
    <div>
        <div class="row d-flex justify-content-end">
            <label for="staticEmail" class="col-sm-6 col-form-label font-bold">{{ title }}</label>
            <div class="col-sm-6">
                <select class="form-select" id="timeRange" v-model="selection" @change="updateData">
                    <option v-for="(item, index) in options" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>

        <hr />

        <div id="chart-timeline">
            <VueApexCharts
                type="area"
                height="250"
                ref="chart"
                :options="chartOptions"
                :series="series"
            ></VueApexCharts>
        </div>
    </div>
</template>

<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { ref, reactive, defineProps, onMounted } from 'vue';

const props = defineProps({
    data: {
        required: true,
        type: Object,
    },
    initialValue: {
        required: false,
        type: String,
    },
    description: String,
    title: String,
    options: Array,
});

const series = ref([{ data: props.data }]);

const selection = ref('');

const chart = ref(null);

const chartOptions = reactive({
    chart: {
        type: 'area',
        height: 350,
        toolbar: {
            tools: {
                download: false,
            },
        },
    },

    grid: {
        show: false,
    },
    pan: {
        enabled: false,
    },
    options: {
        plotOptions: {
            bar: {
                borderRadius: 30,
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: ['stepline'],
    },
    xaxis: {
        type: 'datetime',
        tickAmount: 4,
    },
    tooltip: {
        enabled: true,
        style: {
            fontSize: '14px',
            fontFamily: 'Arial, sans-serif',
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const timestamp = w.config.series[0].data[dataPointIndex][0];
            const formattedDate = formatDateTime(timestamp);

            return `<div class="custom-tooltip" style="padding: 5px">
                  <div>${series[seriesIndex][dataPointIndex]} ${props.description} ${formattedDate}</div>
                </div>`;
        },
    },
    fill: {
        type: 'solid',
        colors: ['#c2dbfeff'],
    },
    grid: {
        show: false,
        xaxis: {
            lines: {
                show: false,
            },
        },
        yaxis: {
            lines: {
                show: false,
            },
        },
    },
});

const updateData = () => {
    const currentDate = new Date();
    let startDate, endDate;

    switch (selection.value) {
        case 'last_day':
            startDate = new Date();
            startDate.setHours(currentDate.getHours() - 24);
            endDate = currentDate;
            break;
        case 'last_week':
            startDate = new Date();
            startDate.setDate(currentDate.getDate() - 7);
            endDate = currentDate;
            break;
        case 'last_14_days':
            startDate = new Date();
            startDate.setDate(currentDate.getDate() - 14);
            endDate = currentDate;
            break;
        case 'last_month':
            startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            break;
        // case 'all':
        //     startDate = new Date(Math.min(...props.data.map((entry) => entry[0])));
        //     endDate = new Date(Math.max(...props.data.map((entry) => entry[0])));
        //     break;
    }

    if (startDate && endDate) {
        chart.value.zoomX(startDate.getTime(), endDate.getTime());
    }
};

const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month} ás ${hours}:${minutes}`;
};

onMounted(() => {
    setTimeout(() => {
        selection.value = props.options[0].value;
        updateData();
    }, 1000);
});
</script>
