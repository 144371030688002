<template>
    <div></div>
</template>

<script setup>
import { toast } from 'vue3-toastify';
import { onMounted, computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

import 'vue3-toastify/dist/index.css';

const page = usePage();
const flashMessage = computed(() => page.props.flash.message?.text);
const flashMessageType = computed(() => page.props.flash.message?.type);

const notify = (message, type) => {
    toast(message, {
        type: type,
        autoClose: 4000,
        position: toast.POSITION.BOTTOM_RIGHT,
    });
};

onMounted(() => {
    if (flashMessage.value) {
        notify(flashMessage.value, flashMessageType.value);
    }
});
</script>
