<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({ roles: Object });

const form = useForm({
    name: null,
    profile_image_url: '/img/avatars/male.png',
    email: null,
    role_id: props.roles[0].id,
});

const inputRef = ref(null);

const store = () => form.post('/dashboard/users');

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.profile_image_url = e.target.result;
    };
    reader.readAsDataURL(blob);
};
</script>

<template>
    <Dashboard page-title="Novo usuário">
        <Breadcrumb
            current-page-name="Novo usuário"
            :link-list="[
                {
                    previous_page_url: 'users',
                    previous_page_name: 'Usuários',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Novo usuário</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.profile_image_url"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon class="text-white" :icon="['fas', 'pen']" />
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-danger mx-2"
                    role="alert"
                    v-if="form.errors.profile_image_url"
                >
                    {{ form.errors.profile_image_url }}
                </div>

                <div class="col-md-4">
                    <label for="name" class="form-label"> Nome do usuário </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Micheline Paiva"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="name" class="form-label"> Email </label>

                    <input
                        id="email"
                        v-model="form.email"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="michele_paiva@email.com"
                        required
                    />
                    <small v-if="form.errors.email" class="text-danger mt-3">
                        {{ form.errors.email }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="role" class="form-label">Função</label>
                    <select class="form-select" name="role" v-model="form.role_id">
                        <option v-for="(role, index) in roles" :key="index" :value="role.id">
                            {{ role.display_name }}
                        </option>
                    </select>
                    <small class="text-danger mt-3" v-if="form.errors.role">{{
                        form.errors.role
                    }}</small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>
            <PhotoEditModal
                v-if="form.profile_image_url"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
