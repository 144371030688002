<script setup>
import { Link } from '@inertiajs/vue3';

import Badge from '@/Pages/Dashboard/Shared/Badge.vue';

const props = defineProps({
    data: Array,
});

const getValueOrDefault = (value, defaultValue = 'Não informado') => {
    return value ? value : defaultValue;
};
</script>

<template>
    <div class="px-5 py-4 mb-3 simple-data-card-member">
        <div class="mb-3">
            <slot name="badge" />
            <Badge :name="data.member.marital_status" />
        </div>
        <div class="d-flex align-items-center justify-content-between">
            <div v-if="data.member.marital_status == 'MARRIED'" class="marital-status-data">
                Casado(a) com
                <Link
                    v-if="data.member.spouse.member_id"
                    :href="`/dashboard/members/${data.member.spouse.member_id}`"
                >
                    {{ data.member.spouse.name }}
                </Link>
                <span v-else>{{ data.member.spouse.name }}</span>
            </div>
        </div>
        <hr />
        <form action="">
            <fieldset class="row" disabled>
                <div class="col-md-6">
                    <label for="address" class="form-label">Endereço</label>
                    <input
                        id="address"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        :value="getValueOrDefault(data.member.first_address?.address)"
                    />
                </div>

                <div class="col-md-3 mt-2 mt-sm-0">
                    <label for="number" class="form-label">Número</label>
                    <input
                        id="number"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                        :value="getValueOrDefault(data.member.first_address?.number)"
                    />
                </div>

                <div class="col-md-3 mt-2 mt-sm-0">
                    <label for="neighborhood" class="form-label">Bairro</label>
                    <input
                        id="neighborhood"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        :value="getValueOrDefault(data.member.first_address?.neighborhood)"
                    />
                </div>
            </fieldset>
        </form>
        <hr />
        <p v-if="data.observations" class="p-3 bg-info bg-opacity-10 border border-info my-3">
            {{ data.observations }}
        </p>

        <h6>Conselheiros</h6>

        <div class="row" v-if="data.counselors.length">
            <div
                v-for="(counselor, index) in data.counselors"
                :key="index"
                class="col-md-2 col-6 counselors mt-3"
            >
                <Link class="d-block text-center" :href="`/dashboard/counselors/${counselor.id}`">
                    <img
                        class="image-counselor border border-info border-3"
                        :src="`/storage/${counselor.identification_photo}`"
                        alt=""
                    />
                </Link>
                <span class="name d-block text-center mt-2">
                    {{ counselor.name }}
                </span>
            </div>
        </div>
        <div v-else>Nenhum conselheiro econtrado</div>
    </div>
</template>
