<script>
export default function useDateFormat() {
    function simplifyDate(dateString) {
        const months = [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
        ];

        let day, month, hour, minute;

        // Verifica se a data está no formato 'YYYY/MM/DD HH:mm'
        if (dateString.includes('/')) {
            const parts = dateString.split(' ');
            const dateParts = parts[0].split('/');
            const timeParts = parts[1].split(':');

            day = parseInt(dateParts[0]);
            month = parseInt(dateParts[1]);
            hour = parseInt(timeParts[0]);
            minute = parseInt(timeParts[1]);
        }
        // Verifica se a data está no formato 'YYYY-MM-DDTHH:mm:ss.sssZ'
        else if (dateString.includes('-')) {
            const date = new Date(dateString);

            day = date.getUTCDate();
            month = date.getUTCMonth() + 1; // Os meses em JavaScript começam do 0
            hour = date.getUTCHours();
            minute = date.getUTCMinutes();
        }

        const formattedDate = `${day} de ${months[month - 1]} às ${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

        return formattedDate;
    }

    return {
        simplifyDate,
    };
}
</script>
