<script setup>
import { ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    censu: Array,
});

const form = useForm({
    identification: props.censu.identification,
    number_people_present: props.censu.number_people_present,
    number_visitors: props.censu.number_visitors,
    number_people_saved: props.censu.number_people_saved,
    number_people_cured: props.censu.number_people_cured,
    number_people_baptized_holy_spirit: props.censu.number_people_baptized_holy_spirit,
    number_people_youtube: props.censu.number_people_youtube,
    number_gv_teens: props.censu.number_gv_teens,
    number_gv_teens_saved: props.censu.number_gv_teens_saved,
    number_children_zero_to_two_years_old: props.censu.number_children_zero_to_two_years_old,
    number_children_three_to_six_years_old: props.censu.number_children_three_to_six_years_old,
    number_children_seven_to_twelve_years_old:
        props.censu.number_children_seven_to_twelve_years_old,
    number_children_saved: props.censu.number_children_saved,
    number_visitors_children: props.censu.number_visitors_children,
    number_teachers: props.censu.number_teachers,
    number_assistant_teachers: props.censu.number_assistant_teachers,
    number_leaders: props.censu.number_leaders,
    number_secretariats: props.censu.number_secretariats,
});

const update = () => form.put(`/dashboard/census/${props.censu.id}`);

const censuEditModalCloseButton = ref(null);

const destroy = () => {
    censuEditModalCloseButton.value.click();
    router.delete(`/dashboard/census/${props.censu.id}`);
};
</script>

<template>
    <Dashboard page-title="Atualizar Censo">
        <Breadcrumb
            current-page-name="Atualizar Censo"
            :link-list="[{ previous_page_url: 'census', previous_page_name: 'Censos' }]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link :href="`/dashboard/census/${censu.id}`" class="btn btn-success btn-add me-2">
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#censuEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <div class="px-5 py-4 form-card">
            <form>
                <div class="row g-3 my-2 px-0">
                    <legend class="m-0">Dados iniciais</legend>

                    <div class="col-md-8">
                        <label for="event_id" class="form-label"> Evento </label>

                        <input
                            id="identification"
                            type="text"
                            class="form-control"
                            :value="`${censu.identification} - ${censu.event.start_time}`"
                            disabled
                            required
                        />

                        <small v-if="form.errors.event_id" class="text-danger mt-3">
                            {{ form.errors.event_id }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_people_present" class="form-label">
                            Pessoas presentes
                        </label>

                        <input
                            id="number_people_present"
                            v-model.number="form.number_people_present"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_people_present" class="text-danger mt-3">
                            {{ form.errors.number_people_present }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_visitors" class="form-label"> Visitantes </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_visitors"
                            v-model.number="form.number_visitors"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_visitors" class="text-danger mt-3">
                            {{ form.errors.number_visitors }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_people_saved" class="form-label"> Pessoas salvas </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_saved"
                            v-model.number="form.number_people_saved"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_people_saved" class="text-danger mt-3">
                            {{ form.errors.number_people_saved }}
                        </small>
                    </div>

                    <div class="col-md-4">
                        <label for="number_people_cured" class="form-label">
                            Pessoas curadas
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_cured"
                            v-model.number="form.number_people_cured"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_people_cured" class="text-danger mt-3">
                            {{ form.errors.number_people_cured }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_people_baptized_holy_spirit" class="form-label">
                            Pessoas batizadas no Espírito Santo
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_baptized_holy_spirit"
                            v-model.number="form.number_people_baptized_holy_spirit"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small
                            v-if="form.errors.number_people_baptized_holy_spirit"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_people_baptized_holy_spirit }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_people_youtube" class="form-label">
                            Pessoas no YouTube
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_people_youtube"
                            v-model.number="form.number_people_youtube"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                        />

                        <small v-if="form.errors.number_people_youtube" class="text-danger mt-3">
                            {{ form.errors.number_people_youtube }}
                        </small>
                    </div>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 mt-3 form-card mt-3">
            <form>
                <div class="row g-3 px-0 my-2">
                    <legend class="m-0">Crianças e Adolescentes</legend>

                    <div class="col-md-6">
                        <label for="number_visitors_children" class="form-label"
                            >Crianças Visitantes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_visitors_children"
                            v-model.number="form.number_visitors_children"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_visitors_children" class="text-danger mt-3">
                            {{ form.errors.number_visitors_children }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_gv_teens" class="form-label"> Adolescentes GV </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_gv_teens"
                            v-model.number="form.number_gv_teens"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_gv_teens" class="text-danger mt-3">
                            {{ form.errors.number_gv_teens }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_zero_to_two_years_old" class="form-label">
                            Crianças de 0 a 2 anos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_zero_to_two_years_old"
                            v-model.number="form.number_children_zero_to_two_years_old"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_children_zero_to_two_years_old"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_children_zero_to_two_years_old }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_gv_teens_saved" class="form-label">
                            Adolescentes GV salvos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_gv_teens_saved"
                            v-model.number="form.number_gv_teens_saved"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_gv_teens_saved" class="text-danger mt-3">
                            {{ form.errors.number_gv_teens_saved }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_three_to_six_years_old" class="form-label">
                            Crianças de 3 a 6 anos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_three_to_six_years_old"
                            v-model.number="form.number_children_three_to_six_years_old"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_children_three_to_six_years_old"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_children_three_to_six_years_old }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_saved" class="form-label"
                            >Crianças salvas
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_saved"
                            v-model.number="form.number_children_saved"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_children_saved" class="text-danger mt-3">
                            {{ form.errors.number_children_saved }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_children_seven_to_twelve_years_old" class="form-label">
                            Crianças de 7 a 12 anos
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_children_seven_to_twelve_years_old"
                            v-model.number="form.number_children_seven_to_twelve_years_old"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_children_seven_to_twelve_years_old"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_children_seven_to_twelve_years_old }}
                        </small>
                    </div>
                </div>
            </form>
        </div>

        <div class="px-5 py-4 form-card mt-3">
            <form @submit.prevent="method">
                <div class="row g-3 my-2 px-0">
                    <legend class="m-0">Outros</legend>

                    <div class="col-md-6">
                        <label for="number_teachers" class="form-label">
                            Professores presentes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_teachers"
                            v-model.number="form.number_teachers"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_teachers" class="text-danger mt-3">
                            {{ form.errors.number_teachers }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_assistant_teachers" class="form-label">
                            Assistentes de professores presentes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_assistant_teachers"
                            v-model.number="form.number_assistant_teachers"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small
                            v-if="form.errors.number_assistant_teachers"
                            class="text-danger mt-3"
                        >
                            {{ form.errors.number_assistant_teachers }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_leaders" class="form-label"> Líderes presentes </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_leaders"
                            v-model.number="form.number_leaders"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_leaders" class="text-danger mt-3">
                            {{ form.errors.number_leaders }}
                        </small>
                    </div>

                    <div class="col-md-6">
                        <label for="number_secretariats" class="form-label">
                            Secretários presentes
                        </label>
                        <small class="optional ms-2">(opcional)</small>

                        <input
                            id="number_secretariats"
                            v-model.number="form.number_secretariats"
                            type="number"
                            class="form-control"
                            min="0"
                            max="100000"
                            required
                        />

                        <small v-if="form.errors.number_secretariats" class="text-danger mt-3">
                            {{ form.errors.number_secretariats }}
                        </small>
                    </div>
                </div>
            </form>

            <div class="col text-end mt-4">
                <button
                    class="btn btn-primary"
                    type="button"
                    :disabled="form.processing"
                    @click="update()"
                >
                    <span
                        v-if="form.processing"
                        class="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    />
                    Atualizar dados
                </button>
            </div>
        </div>
        <ConfirmationModal
            modal_id="censuEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="censuEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
