<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    roles: Object,
});

const goToRolePage = (memberId) => router.get(`/dashboard/roles/${memberId}`);

const clearQueryParams = () => router.replace('/dashboard/roles');

const searchForm = reactive({
    display_name: null,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const seacher = () =>
    router.get('/dashboard/roles', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    clearQueryParams();
});
</script>

<template>
    <Dashboard page-title="Funções">
        <Breadcrumb current-page-name="Funções" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Funções</h4>
                <Link :href="`/dashboard/roles/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da função</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Aline Andrade"
                            @input="compositionSeacher('display_name', $event)"
                        />
                    </div>
                </div>
            </div>
            <div class="row px-4 mt-3">
                <hr />
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-roles">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">ID</th>
                                <th class="text-center" scope="col">Prefixo</th>
                                <th class="text-center" scope="col">Nome da função</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(member, index) in roles.data"
                                :key="index"
                                @click="goToRolePage(member.id)"
                            >
                                <td class="text-center">
                                    {{ member.id }}
                                </td>
                                <td class="text-center">
                                    {{ member.name }}
                                </td>
                                <td class="text-center">
                                    {{ member.display_name }}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="5">
                                    <Pagination
                                        :data="roles.meta"
                                        reference-date="roles"
                                        identification="funções"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
